<template>
  <div class="apply-details" v-loading="loading">
    <el-card class="st-card">
      <h3>申请详情</h3>

      <div class="details-main">
        <div class="state-item">
          <span>申请状态：</span>
          <el-tag v-if="this.detailsInfo.status === 0">待审核</el-tag>
          <el-tag type="success" v-if="this.detailsInfo.status === 1">已通过</el-tag>
          <el-tag type="danger" v-if="this.detailsInfo.status === -1">已拒绝</el-tag>
        </div>
        <el-row>
          <el-col :span="12">
            <h4>联系人信息</h4>
            <div class="form-main">
              <el-form :model="contact" :label-width="labelWidth">
                <el-form-item label="联系人姓名：">
                  <p>{{ contact.name }}</p>
                </el-form-item>
                <el-form-item label="联系人邮箱：">
                  <p>{{ contact.email }}</p>
                </el-form-item>
                <el-form-item label="联系人手机号码：">
                  <p>{{ contact.mobile }}</p>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <h4>营业执照信息</h4>
            <div class="form-main">
              <el-form :model="enterpriseInfo" :label-width="labelWidth">
                <el-form-item label="营业执照图片：">
                  <div class="image">
                    <img v-if="enterpriseInfo.licImg" :src="enterpriseInfo.licImg" class="avatar">
                  </div>
                </el-form-item>
                <el-form-item label="公司名称：">
                  <p>{{ enterpriseInfo.name }}</p>
                </el-form-item>
                <el-form-item label="统一社会信用代码：">
                  <p>{{ enterpriseInfo.tax }}</p>
                </el-form-item>
                <el-form-item label="住所：">
                  <p>{{ enterpriseInfo.addr }}</p>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <h4>法人信息</h4>
            <div class="form-main">
              <el-form :model="ownerIdCardInfo" :label-width="labelWidth">
                <el-form-item label="法人身份证头像面：">
                  <div class="image">
                    <img v-if="ownerIdCardInfo.idCardBackUrl" :src="ownerIdCardInfo.idCardBackUrl" class="avatar">
                  </div>
                </el-form-item>
                <el-form-item label="法人身份证国徽面：">
                  <div class="image">
                    <img v-if="ownerIdCardInfo.idCardFrontUrl" :src="ownerIdCardInfo.idCardFrontUrl" class="avatar">
                  </div>
                </el-form-item>
                <el-form-item label="法人姓名：">
                  <p>{{ ownerIdCardInfo.name }}</p>
                </el-form-item>
                <el-form-item label="身份证号码：">
                  <p>{{ ownerIdCardInfo.idCardNum }}</p>
                </el-form-item>
                <el-form-item label="身份证有效期：">
                  <p>{{ ownerIdCardInfo.idCardExpired }}</p>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <h4>对公账号信息</h4>
            <div class="form-main">
              <el-form :model="enterpriseInfo" :label-width="labelWidth">
                <el-form-item label="开户行：">
                  <p>{{ enterpriseInfo.bank }}</p>
                </el-form-item>
                <el-form-item label="银行卡号：">
                  <p>{{ enterpriseInfo.account }}</p>
                </el-form-item>
                <el-form-item label="开户人姓名：">
                  <p>{{ enterpriseInfo.accountOwner }}</p>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <h4>店铺信息</h4>
            <div class="form-main">
              <el-form :model="shopInfo" :label-width="labelWidth">
                <el-form-item label="店铺招牌(门头照)：">
                  <div class="image">
                    <img v-if="shopInfo.shopImg" :src="shopInfo.shopImg" class="avatar">
                  </div>
                </el-form-item>
                <el-form-item label="店铺名称：">
                  <p>{{ shopInfo.name }}</p>
                </el-form-item>
                <el-form-item label="店铺网址：">
                  <p>{{ shopInfo.website }}</p>
                </el-form-item>
                <el-form-item label="店铺地址：">
                  <p>
                    <span>{{ shopInfo.address.province }}</span>
                    <span>{{ shopInfo.address.city }}</span>
                    <span>{{ shopInfo.address.region }}</span>
                    <span>{{ shopInfo.address.street }}</span>
                  </p>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="footer">
        <el-button
          type="primary"
          v-if="this.detailsInfo.status === 0"
          @click="addMarket(1)">
          通过
        </el-button>
        <el-button
          type="danger"
          v-if="this.detailsInfo.status === 0"
          @click="updataApplyStatus(-1)">
          拒绝
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: '',
  data () {
    return {
      loading: false,
      labelWidth: '140px',
      detailsInfo: {},
      contact: {},
      ownerIdCardInfo: {},
      enterpriseInfo: {},
      shopInfo: {
        address: {}
      }
    }
  },
  mounted () {
    this.getApplyDetails()
  },
  methods: {
    // 获取申报详细
    getApplyDetails () {
      this.loading = true
      this.$api.apply.items({
        id: this.$route.query.id
      }).then(res => {
        this.loading = false
        if (res.data.success) {
          this.detailsInfo = res.data.result.data[0]
          this.contact = this.detailsInfo.contact
          this.ownerIdCardInfo = this.detailsInfo.ownerIdCardInfo
          this.enterpriseInfo = this.detailsInfo.enterpriseInfo
          this.shopInfo = this.detailsInfo.shopInfo
        }
      }).catch(() => {
        this.loading = false
      })
    },

    // 新增机构
    addMarket () {
      this.loading = true
      this.$api.markets.add({
        client_id: this.$config.CLIENT_IDS.ADMIN,
        uid: '',
        code: '',
        EnterpriseInfo: this.enterpriseInfo,
        businessType: 6,
        fullName: this.contact.enterpriseName,
        owner: this.ownerIdCardInfo.name,
        ownerIdCardInfo: _.omit(JSON.parse(JSON.stringify(this.ownerIdCardInfo)), ['name']),
        contact: [
          _.omit(JSON.parse(JSON.stringify(this.contact)), ['enterpriseName'])
        ]
      }).then(res => {
        if (res.status === 200) {
          let marketId = res.data.id
          this.addShop(marketId)
        }
      }).catch(() => {
        this.loading = false
      })
    },

    addShop (marketId) {
      this.$api.shops.add({
        client_id: this.$config.CLIENT_IDS.ADMIN,
        code: '',
        name: this.shopInfo.name,
        businessType: 6,
        customCode: '',
        website: this.shopInfo.website,
        imgUrl: this.shopInfo.shopImg,
        address: this.shopInfo.address,
        market_id: marketId
      }).then(res => {
        if (res.status === 200) {
          this.updataApplyStatus(1)
        }
      }).catch(() => {
        this.loading = false
      })
    },

    // 修改审核状态
    updataApplyStatus (status) {
      this.$api.apply.update({
        id: this.$route.query.id,
        status: status,
        reason: ''
      }).then(res => {
        if (res.data.success) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.getApplyDetails()
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "./index"
</style>
